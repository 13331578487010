
import {
  defineComponent, reactive, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarNaturezaOperacao from '@/components/Cadastros/PrestacaoServicos/SelecionarNaturezaOperacao.vue';
import { ETipoEmissao } from '@/models/Enumeradores/Fiscal/ETipoEmissao';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import storeSistema from '@/store/storeSistema';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { IDTOPessoaMovimento } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaMovimento';
import ServicoNotaFiscalServico from '@/servicos/Fiscal/ServicoNotaFiscalServico';
import { INotaFiscalServico } from '@/models/Entidades/Fiscal/INotaFiscalServico';
import { EStatusNotaFiscalServico } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/EStatusNotaFiscalServico';
import ServicoServico from '@/servicos/Cadastros/PrestacaoServicos/ServicoServico';
import NotaFiscalServicoImpostos from '@/components/Fiscal/NotasFiscaisServicos/NotaFiscalServicoImpostos.vue';
import NotaFiscalServicoDetalhesPagamento from '@/components/Fiscal/NotasFiscaisServicos/NotaFiscalServicoDetalhesPagamento.vue';
import { ELocalPrestacaoServico } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/ELocalPrestacaoServico';
import NotaFiscalServicoItens from '@/components/Fiscal/NotasFiscaisServicos/NotaFiscalServicoItens.vue';

export default defineComponent({
  name: 'NotaFiscalServicoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    Icone,
    PreviewPdf,
    SelecionarData,
    SelecionarPessoa,
    SelecionarNaturezaOperacao,
    NotaFiscalServicoImpostos,
    NotaFiscalServicoDetalhesPagamento,
    NotaFiscalServicoItens,
  },
  emits: ['update:operacao', 'update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const servicoNotaFiscalServico = new ServicoNotaFiscalServico();
    const servicoCalculosComerciais = new ServicoCalculosComerciais();
    const servicoSistema = new ServicoSistema();
    const servicoServico = new ServicoServico();
    servicoServico.requisicaoSistema();

    let debounceAtualizacaoTotalizadores = 0;
    let debounceSalvamentoAutomatico = 0;
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis,
      preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    telaBase.identificadorRecurso = 'NOTAS_FISCAIS_SERVICO';
    telaBase.identificadorPermissao = 'AUT_NOTAS_FISCAIS_SERVICO';

    const state = reactive({
      dadosPessoaMovimento: {} as IDTOPessoaMovimento,
      notaFiscalServico: {} as INotaFiscalServico,
      marcadores: [] as string[],
      telaMobile: false,
      totalDetalhesPagamento: 0,
      recuperarSalvamentoAutomatico: false,
      salvamentoAutomatico: {} as ISalvamentoAutomatico,
      editavel: false,
      arquivosPdf: [] as IArquivoPdf[],
    });

    function obtemIdentificadorSalvamentoAutomatico(): string {
      return `${telaBase.identificadorRecurso}_${state.notaFiscalServico.codigo}`;
    }

    async function salvamentoAutomatico() {
      if (state.notaFiscalServico.codigo > 0) {
        clearTimeout(debounceSalvamentoAutomatico);
        debounceSalvamentoAutomatico = setTimeout(async () => {
          const identificador = obtemIdentificadorSalvamentoAutomatico();
          state.salvamentoAutomatico.data = UtilitarioData.obterDataAtual();
          state.salvamentoAutomatico.codigoEmpresa = state.notaFiscalServico.codigoEmpresa;
          state.salvamentoAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
          state.salvamentoAutomatico.identificador = identificador;
          state.salvamentoAutomatico.dados = JSON.stringify(state.notaFiscalServico);
          // Salva informações - LocalStorage
          await servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}_${state.salvamentoAutomatico.codigoUsuario}`, state.salvamentoAutomatico);
          // Salva informações - API Servidor
          const retornoSalvamento = await servicoSistema.salvamentoAutomaticoApi(state.salvamentoAutomatico);
          if (retornoSalvamento.status === EStatusRetornoRequisicao.Sucesso) {
            state.salvamentoAutomatico.codigo = retornoSalvamento.codigoRegistro;
          }
          state.recuperarSalvamentoAutomatico = false;
        }, 1000);
      }
    }

    function obterDescricaoStatus(status: EStatusNotaFiscalServico) {
      switch (status) {
        case EStatusNotaFiscalServico.Pendente:
          return 'Pendente';
        case EStatusNotaFiscalServico.Autorizada:
          return 'Autorizada';
        case EStatusNotaFiscalServico.Cancelada:
          return 'Cancelada';
        case EStatusNotaFiscalServico.EmProcessamento:
          return 'Em Processamento';
        default:
          return '';
      }
    }

    function obterCorStatus(status: EStatusNotaFiscalServico) {
      switch (status) {
        case EStatusNotaFiscalServico.Pendente:
          return '#969696';
        case EStatusNotaFiscalServico.EmProcessamento:
          return '#d6c720';
        case EStatusNotaFiscalServico.Autorizada:
          return '#1843b8';
        default:
          return '#cf2751';
      }
    }

    async function limparTela() {
      state.notaFiscalServico = servicoServico.instanciarNotaFiscalServico(props.operacao.empresaSelecionada, storeSistema.getters.codigoUsuarioAutenticado());
      const dadosEmpresa = storeSistema.state.empresasDisponiveis.find((c) => c.codigoEmpresa === state.notaFiscalServico.codigoEmpresa);
      if (dadosEmpresa != null) {
        state.notaFiscalServico.prestador.regimeTributario = dadosEmpresa.regimeTributario;
      }
      state.notaFiscalServico.servicos.push(servicoServico.instanciarItemNotaFiscalServico());
      state.editavel = false;
    }

    async function atualizarValoresTotais() {
      clearTimeout(debounceAtualizacaoTotalizadores);
      debounceAtualizacaoTotalizadores = setTimeout(async () => {
        state.notaFiscalServico.totalServicos = 0;
        state.notaFiscalServico.totalDesconto = 0;
        state.notaFiscalServico.totalNotaFiscal = 0;
        state.notaFiscalServico.totalImpostos.totalBaseCalculo = 0;
        state.notaFiscalServico.totalImpostos.totalIss = 0;
        state.notaFiscalServico.totalImpostos.totalIssRetido = 0;
        state.notaFiscalServico.totalImpostos.totalPis = 0;
        state.notaFiscalServico.totalImpostos.totalCofins = 0;
        state.notaFiscalServico.totalImpostos.totalInss = 0;
        state.notaFiscalServico.totalImpostos.totalIr = 0;
        state.notaFiscalServico.totalImpostos.totalCsll = 0;
        state.notaFiscalServico.totalImpostos.totalCredito = 0;
        state.notaFiscalServico.totalImpostos.totalValorAproximadoTributos = 0;

        for (let index = 0; index < state.notaFiscalServico.servicos.length; index += 1) {
          state.notaFiscalServico.totalDesconto += state.notaFiscalServico.servicos[index].impostos.descontoIncondicionado;
          state.notaFiscalServico.totalDesconto += state.notaFiscalServico.servicos[index].impostos.descontoCondicionado;
          state.notaFiscalServico.totalServicos += state.notaFiscalServico.servicos[index].valorServico;
          state.notaFiscalServico.servicos[index].valorTotal = (state.notaFiscalServico.servicos[index].valorServico - state.notaFiscalServico.servicos[index].impostos.descontoIncondicionado - state.notaFiscalServico.servicos[index].impostos.descontoCondicionado);
          if (UtilitarioGeral.validaCodigo(state.notaFiscalServico.servicos[index].codigoServico)) {
            const retornoCalculoTributacoes = await servicoCalculosComerciais.calcularTributacoesServico(state.notaFiscalServico.servicos[index], state.notaFiscalServico.prestador.regimeTributario);
            if (UtilitarioGeral.objetoValido(retornoCalculoTributacoes)) {
              state.notaFiscalServico.servicos[index].impostos.baseCalculo = retornoCalculoTributacoes.baseCalculo;
              state.notaFiscalServico.servicos[index].impostos.valorIss = retornoCalculoTributacoes.valorIss;
              state.notaFiscalServico.servicos[index].impostos.valorIssRetido = retornoCalculoTributacoes.valorIssRetido;
              state.notaFiscalServico.servicos[index].impostos.valorPis = retornoCalculoTributacoes.valorPis;
              state.notaFiscalServico.servicos[index].impostos.valorCofins = retornoCalculoTributacoes.valorCofins;
              state.notaFiscalServico.servicos[index].impostos.valorIr = retornoCalculoTributacoes.valorIr;
              state.notaFiscalServico.servicos[index].impostos.valorInss = retornoCalculoTributacoes.valorInss;
              state.notaFiscalServico.servicos[index].impostos.valorCsll = retornoCalculoTributacoes.valorCsll;
              state.notaFiscalServico.servicos[index].impostos.valorAproximadoTributos = retornoCalculoTributacoes.valorAproximadoTributos;

              state.notaFiscalServico.totalImpostos.totalBaseCalculo += state.notaFiscalServico.servicos[index].impostos.baseCalculo;
              state.notaFiscalServico.totalImpostos.totalIss += state.notaFiscalServico.servicos[index].impostos.valorIss;
              state.notaFiscalServico.totalImpostos.totalIssRetido += state.notaFiscalServico.servicos[index].impostos.valorIssRetido;
              state.notaFiscalServico.totalImpostos.totalPis += state.notaFiscalServico.servicos[index].impostos.valorPis;
              state.notaFiscalServico.totalImpostos.totalCofins += state.notaFiscalServico.servicos[index].impostos.valorCofins;
              state.notaFiscalServico.totalImpostos.totalIr += state.notaFiscalServico.servicos[index].impostos.valorIr;
              state.notaFiscalServico.totalImpostos.totalInss += state.notaFiscalServico.servicos[index].impostos.valorInss;
              state.notaFiscalServico.totalImpostos.totalCsll += state.notaFiscalServico.servicos[index].impostos.valorCsll;
              state.notaFiscalServico.totalImpostos.totalValorAproximadoTributos += state.notaFiscalServico.servicos[index].impostos.valorAproximadoTributos;
            }
          }
        }
        // todo verificar calculos
        state.notaFiscalServico.totalNotaFiscal = (state.notaFiscalServico.totalServicos - state.notaFiscalServico.totalDesconto);

        salvamentoAutomatico();
      }, 600);
    }

    function atualizarTotalDetalhesPagamento() {
      state.totalDetalhesPagamento = 0;
      state.notaFiscalServico.detalhesPagamento.forEach((d) => {
        state.totalDetalhesPagamento += d.valorTotal;
      });
    }

    async function obterNotaFiscalServico(codigo: number) {
      state.notaFiscalServico = await servicoNotaFiscalServico.obter(codigo, true);
      const identificador = obtemIdentificadorSalvamentoAutomatico();
      const salvamentoAutomaticoApi = await servicoSistema.obterUltimoSalvamentoAutomaticoApi(state.notaFiscalServico.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), identificador);
      const salvamentoAutomaticoLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(`${identificador}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
      state.salvamentoAutomatico = servicoSistema.obterSalvamentoMaisRecente(salvamentoAutomaticoApi, salvamentoAutomaticoLocalStorage);

      if (UtilitarioData.validaDataPreenchida(state.salvamentoAutomatico.data) && !UtilitarioData.validaDataPreenchida(state.notaFiscalServico.dataEmissaoRps)) {
        state.recuperarSalvamentoAutomatico = true;
      } else if (UtilitarioData.validaDataPreenchida(state.salvamentoAutomatico.data) && UtilitarioData.validaDataPreenchida(state.notaFiscalServico.dataEmissaoRps)) {
        if (UtilitarioData.verificaDataMaisRecente(state.salvamentoAutomatico.data, state.notaFiscalServico.dataEmissaoRps)) { state.recuperarSalvamentoAutomatico = true; }
      }

      state.editavel = state.notaFiscalServico.status === EStatusNotaFiscalServico.Pendente;
    }

    function validarCampos() {
      if (!UtilitarioGeral.valorValido(state.notaFiscalServico.serieRps)) {
        apresentarMensagemAlerta('A série do RPS deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.notaFiscalServico.dataEmissaoRps)) {
        apresentarMensagemAlerta('A data de emissão deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.notaFiscalServico.dataCompetencia)) {
        apresentarMensagemAlerta('A data de Competência deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.notaFiscalServico.naturezaOperacao)) {
        apresentarMensagemAlerta('A natureza da operação deve ser informada!');
        return false;
      }

      return true;
    }

    async function salvar() {
      if (!validarCampos()) return;

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso('Aguarde por favor.. Estamos salvando a Nota Fiscal de Serviço');

      if (state.notaFiscalServico.codigo === 0 || state.notaFiscalServico.codigo === undefined) {
        retorno = await servicoNotaFiscalServico.incluir(state.notaFiscalServico);
      } else {
        retorno = await servicoNotaFiscalServico.alterar(state.notaFiscalServico);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        apresentarMensagemSucesso(retorno.mensagem);
        const identificador = obtemIdentificadorSalvamentoAutomatico();
        // Remove informações - LocalStorage
        servicoSistema.removerSalvamentoAutomaticoLocalStorage(`${identificador}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
        // Remove informações - API Servidor
        await servicoSistema.removerSalvamentoAutomaticoApi(state.notaFiscalServico.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), identificador);

        if (state.notaFiscalServico.codigo === 0) {
          emit('confirmacao');
          modalBase.computedVisivel = false;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function emitir() {
      if (!validarCampos()) return;

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso('Aguarde por favor.. Estamos salvando a Nota Fiscal de Serviço');

      retorno = await servicoNotaFiscalServico.emitir(state.notaFiscalServico.codigo);

      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      telaBase.carregando = true;
      limparTela();
      state.editavel = true;
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasDisponiveis();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        state.notaFiscalServico.serieRps = await servicoNotaFiscalServico.obterSerie(props.operacao.empresaSelecionada);
        if (props.operacao.codigoRegistro > 0 && props.operacao.codigoRegistro !== undefined) {
          await obterNotaFiscalServico(props.operacao.codigoRegistro);
        }
      }
      telaBase.carregando = false;
    });

    function recuperarSalvamentoAutomatico() {
      if (UtilitarioGeral.valorValido(state.salvamentoAutomatico.dados)) {
        const notaFiscalServicoSalvaAutomatico = JSON.parse(state.salvamentoAutomatico.dados);
        if (UtilitarioGeral.objetoValido(notaFiscalServicoSalvaAutomatico)) {
          state.notaFiscalServico = notaFiscalServicoSalvaAutomatico;
          state.recuperarSalvamentoAutomatico = false;
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
      }
    }

    function concluirEtapa() {
      Modal.confirm({
        title: `Realmente deseja passar da etapa ${obterDescricaoStatus(state.notaFiscalServico.status)} para a etapa ${obterDescricaoStatus(state.notaFiscalServico.status + 1)}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoNotaFiscalServico.alterarEtapa(state.notaFiscalServico.codigo, state.notaFiscalServico.codigoEmpresa, state.notaFiscalServico.status + 1);
          if (retorno.status === 1) {
            state.notaFiscalServico.status += 1;
            emit('confirmacao');
          } else if (retorno.status === 2) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function retornarEtapa() {
      Modal.confirm({
        title: `Realmente deseja passar da etapa ${obterDescricaoStatus(state.notaFiscalServico.status)} para a etapa ${obterDescricaoStatus(state.notaFiscalServico.status - 1)}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoNotaFiscalServico.alterarEtapa(state.notaFiscalServico.codigo, state.notaFiscalServico.codigoEmpresa, state.notaFiscalServico.status - 1);
          if (retorno.status === 1) {
            state.notaFiscalServico.status -= 1;
            emit('confirmacao');
          } else if (retorno.status === 2) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function cancelar() {
      Modal.confirm({
        title: 'Realmente deseja cancelar a nota fiscal de serviço?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoNotaFiscalServico.alterarEtapa(state.notaFiscalServico.codigo, state.notaFiscalServico.codigoEmpresa, EStatusNotaFiscalServico.Cancelada);
          if (retorno.status === 1) {
            state.notaFiscalServico.status = EStatusNotaFiscalServico.Cancelada;
            emit('confirmacao');
          } else if (retorno.status === 2) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function excluir() {
      Modal.confirm({
        title: 'Realmente deseja excluir a nota fiscal de serviço?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoNotaFiscalServico.excluirVarios(state.notaFiscalServico.codigoEmpresa, [state.notaFiscalServico.codigo]);
          if (retorno.status !== 1) {
            apresentarMensagemErro(retorno.mensagem);
          } else {
            emit('confirmacao');
            modalBase.computedVisivel = false;
          }
        },
      });
    }

    async function imprimir() {
      const retornoRelatorio = await servicoNotaFiscalServico.imprimir(state.notaFiscalServico.codigo, state.notaFiscalServico.codigoEmpresa, ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    function atualizarLocalPrestacao(local: ELocalPrestacaoServico) {
      state.notaFiscalServico.localServico = local;
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      ETipoEmissao,
      ETipoMovimentoComercial,
      UtilitarioGeral,
      UtilitarioMascara,
      apresentarRetornoRequisicao,
      obterDescricaoStatus,
      obterCorStatus,
      atualizarValoresTotais,
      salvar,
      EStatusNotaFiscalServico,
      ETipoRelacaoPessoa,
      concluirEtapa,
      retornarEtapa,
      cancelar,
      excluir,
      salvamentoAutomatico,
      imprimir,
      recuperarSalvamentoAutomatico,
      servicoServico,
      atualizarTotalDetalhesPagamento,
      atualizarLocalPrestacao,
      emitir,
    };
  },
});
